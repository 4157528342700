body {
  background-color: #282c34;
}

.App {
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.HomeAccount {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
}

.HomeAccountItem {
  margin-right: 10px;
}

.Center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.UserName {
  color: chocolate;
}
